import React from 'react'
import styled from '@emotion/styled'
import { Box, Flex } from '@chakra-ui/core'
import { themeUtils } from '../../../themeUtils'
import { OrderShow } from '../../../schema/queries/__generated__/OrderShow'
import { MultiLineAddress, ParagraphText } from '../../Shared'
import { CarrierText, Status } from '.'
import { OrderStatusEnum } from '../../../__generated__/globalTypes'
import { formattedDate } from '../../../utils'
import { statusSubmittedText } from '../../../utils/commonUtils'
import { useTranslation } from 'react-i18next'

type Props = OrderShow

const { midnightBlue, slateGrey } = themeUtils.colors
const HighlightText = styled.span`
  font-weight: 500;
  color: ${midnightBlue};
`

export const StatusWidget: React.FC<Props> = ({ order }) => {
  const { t } = useTranslation(['orderShowScreen'])
  if (!order) return null
  const {
    waybillNumber,
    carrier,
    status,
    actualShipDate,
    deliveryDate,
    usingMnxReservedSurplusInventory,
    shippingLink,
  } = order
  const submittedDate = order.createdAt
  const confirmedDate = order.acceptedAt || order.reservedAt
  const cancellationRequestedDate = order.cancellationRequestedAt
  const cancelledDate = order.cancelledAt
  const isShipped = status === OrderStatusEnum.SHIPPED && actualShipDate
  const toDisplayDeliveryDate = [
    OrderStatusEnum.ACCEPTED,
    OrderStatusEnum.BUILD_PENDING,
    OrderStatusEnum.BUILT,
  ]
  if (usingMnxReservedSurplusInventory) {
    toDisplayDeliveryDate.push(
      OrderStatusEnum.RESERVED,
      OrderStatusEnum.RELEASED
    )
  }
  const displayDeliveryDate =
    toDisplayDeliveryDate.includes(status) && deliveryDate

  const shippingLinkTag = <a target='_blank' href={shippingLink || undefined}>{t('statusWidget.trackingLink', { ns: ['orderShowScreen'] })}</a>

  const isCancelling =
    status === OrderStatusEnum.CANCELLING ||
    status === OrderStatusEnum.CANCELLED ||
    status === OrderStatusEnum.VOID
  const isCancelled =
    status === OrderStatusEnum.CANCELLED || status === OrderStatusEnum.VOID
  const cancellationReason = `${order.cancellationReason?.code} - ${order.cancellationReason?.customerFacingDescription}`

  return (
    <Flex direction="column" position="relative" height="100%">
      {isCancelled && (
        <Status
          status={t('statusWidget.cancelConfirmed', { ns: ['orderShowScreen'] })}
          alert={true}
          active={false}
          dateOfStatus={cancelledDate}
          name={order.cancelledByName as string}
          type={order.cancelledByType as string}
        >
          <ParagraphText color={slateGrey}>
            <b>{t('statusWidget.reason', { ns: ['orderShowScreen'] })}</b> {cancellationReason}
          </ParagraphText>
        </Status>
      )}

      {isCancelling && order.status !== OrderStatusEnum.VOID && (
        <Status
          status={t('statusWidget.requested', { ns: ['orderShowScreen'] })}
          alert={true}
          active={false}
          dateOfStatus={cancellationRequestedDate}
          name={order.cancellationRequestedByName as string}
          type={order.cancellationRequestedByType as string}
        >
          <ParagraphText color={slateGrey}>
            <b>{t('statusWidget.reason', { ns: ['orderShowScreen'] })}</b> {cancellationReason}
          </ParagraphText>
        </Status>
      )}

      {!isCancelling && (
        <Status status={t('statusWidget.shipped', { ns: ['orderShowScreen'] })} active={!!actualShipDate}>
          {(isShipped || displayDeliveryDate) && (
            <>
              <ParagraphText color={slateGrey}>
                {isShipped && formattedDate(actualShipDate)}
                {displayDeliveryDate &&
                  `${t('statusWidget.expectedDelivery', { ns: ['orderShowScreen'] })} ${formattedDate(deliveryDate)}`}
              </ParagraphText>
              <ParagraphText color={slateGrey}>
                {( isShipped || displayDeliveryDate) && shippingLink &&
                  <>
                    {shippingLinkTag}
                  </>
                }
              </ParagraphText>
            </>
          )}
          {isShipped && (
            <Box>
              {carrier && (
                <CarrierText carrier={carrier} waybillNumber={waybillNumber} />
              )}
              {waybillNumber && (
                <ParagraphText color={slateGrey}>
                  <HighlightText>{t('statusWidget.waybill', { ns: ['orderShowScreen'] })}</HighlightText> {waybillNumber}
                </ParagraphText>
              )}
            </Box>
          )}
        </Status>
      )}

      {order.status !== OrderStatusEnum.VOID && (
        <Status
          status={t('statusWidget.confirmed', { ns: ['orderShowScreen'] })}
          dateOfStatus={confirmedDate}
          active={!!confirmedDate}
          name={order.confirmedByName as string}
          type={order.confirmedByType as string}
        />
      )}

      <Status
        status={statusSubmittedText(order.customerPortalOrder)}
        dateOfStatus={submittedDate}
        active={!!submittedDate}
        name={order.createdByName as string}
        type={order.createdByType as string}
      />

      <Box
        backgroundColor={themeUtils.colors.lightGrey}
        padding="2rem"
        borderRadius="1rem"
        marginTop="auto"
        fontSize="1.5rem"
      >
        <ParagraphText variant="title" marginBottom="2rem">
          {t('statusWidget.shippingAddress', { ns: ['orderShowScreen'] })}
        </ParagraphText>
        <ParagraphText color={slateGrey}>
          <MultiLineAddress address={order.address} />
        </ParagraphText>
      </Box>
    </Flex>
  )
}

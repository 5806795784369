import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { resources, options } from './translation'
import LanguageDetector from 'i18next-browser-languagedetector'
import {
  DATE_FORMAT,
  DATE_FR_FORMAT,
  DAY_MONTH_FORMAT,
  DAY_MONTH_FR_FORMAT,
  DAY_OF_WEEK_FORMAT,
  DAY_OF_WEEK_FR_FORMAT,
  LONG_MONTH_FORMAT,
  LONG_MONTH_FR_FORMAT,
  SHORT_MONTH_FORMAT,
  SHORT_MONTH_FR_FORMAT,
} from './constants'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    resources,
    detection: options,
  })

export const getTimeFormat = () => {
  return i18n.language === 'fr' ? 'HH:mm' : 'h:mm a'
}

export const getDateFormat = (formatString: string) => {
  if (i18n.language !== 'fr') {
    return formatString
  }
  switch (formatString) {
    case DATE_FORMAT:
      return DATE_FR_FORMAT
    case DAY_OF_WEEK_FORMAT:
      return DAY_OF_WEEK_FR_FORMAT
    case DAY_MONTH_FORMAT:
      return DAY_MONTH_FR_FORMAT
    case LONG_MONTH_FORMAT:
      return LONG_MONTH_FR_FORMAT
    case SHORT_MONTH_FORMAT:
      return SHORT_MONTH_FR_FORMAT
    default:
      return formatString
  }
}

export default i18n

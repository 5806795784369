import { css } from '@emotion/core'
import { themeUtils } from './themeUtils'
import { darken } from 'polished'

const GlobalStyles = css`
  :root {
    font-size: 62.5%;
  }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  html,
  body {
    height: 100%;
  }
  html {
    box-sizing: border-box;
  }
  body {
    background-color: ${themeUtils.colors.lightGrey};
    color: ${themeUtils.colors.slateGrey};
    font-family: 'Red Hat Display', sans-serif;
    /* Body font size 16px */
    font-size: 1.6rem;
  }
  /* Text Styles */
  h1,
  h2,
  h3,
  h4,
  h4,
  h5,
  h6 {
    margin: 1rem 0;
    font-weight: 700;
    color: ${themeUtils.colors.slateGrey};
  }
  h1 {
    font-size: 3rem;
    color: ${themeUtils.colors.darkBlue};
  }
  h2 {
    font-size: 1.8rem;
  }
  h3 {
    font-size: 1.6rem;
  }
  h4 {
    font-size: 1.4rem;
  }
  h5 {
    font-size: 1.2rem;
  }
  h6 {
    font-size: 0.9rem;
  }
  a {
    text-decoration: underline;
  }
  a:hover {
    color: ${themeUtils.colors.slateGreyLight};
  }
  .subheader {
    color: ${themeUtils.colors.darkBlueOpaque};
  }
  .container {
    margin: 0 auto;
    max-width: ${themeUtils.widths.max};
  }

  .noty_theme__mint.noty_bar {
    margin: 4px 0;
    overflow: hidden;
    border-radius: 2px;
    position: relative;
  }
  .noty_theme__mint.noty_bar .noty_body {
    padding: 10px;
    font-size: 14px;
  }
  .noty_theme__mint.noty_bar .noty_buttons {
    padding: 10px;
  }

  .noty_theme__mint.noty_type__alert,
  .noty_theme__mint.noty_type__notification {
    background-color: #fff;
    border-bottom: 1px solid #d1d1d1;
    color: #2f2f2f;
  }

  .noty_theme__mint.noty_type__warning {
    background-color: ${themeUtils.colors.yellow};
    border-bottom: 1px solid ${darken(0.2, themeUtils.colors.yellow)};
    color: #fff;
  }

  .noty_theme__mint.noty_type__error {
    background-color: ${themeUtils.colors.red};
    border-bottom: 1px solid ${darken(0.2, themeUtils.colors.red)};
    color: #fff;
  }

  .noty_theme__mint.noty_type__info,
  .noty_theme__mint.noty_type__information {
    background-color: ${themeUtils.colors.blue};
    border-bottom: 1px solid ${darken(0.2, themeUtils.colors.blue)};
    color: #fff;
  }

  .noty_theme__mint.noty_type__success {
    background-color: ${themeUtils.colors.green};
    border-bottom: 1px solid ${darken(0.2, themeUtils.colors.green)};
    color: #fff;
  }

  .registrationContainer {
    display: inline-block;
    span {
      display: inline-block;
      font-size: 1.4rem;
      margin-right: 5px;
    }
    a {
      display: inline-block !important;
      font-weight: bold;
      color: #003968;
    }
  }
  
`

export default GlobalStyles

import React from 'react'
import { RouteComponentProps, WindowLocation } from '@reach/router'
import styled from '@emotion/styled'
import { MEDIUM, LARGE, mqNames, themeUtils } from '../../themeUtils'
import {
  RESEND_CONFIRMATION_PATH,
  RESEND_UNLOCK_PATH,
  UNLOCK_ACCOUNT_PATH,
} from '../../routes'
import { AccountLinks } from '../../screens'
import { BTGLogo } from '../Icons'
import { TherasphereLogo } from '../Icons/TherasphereLogo'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 0 ${themeUtils.padding};

  header {
    display: flex;
    justify-content: center;
    padding: 5rem 0 0 0;

    ${mqNames(MEDIUM)} {
      justify-content: flex-start;
      max-width: 30%;
    }
    ${mqNames(LARGE)} {
      justify-content: flex-end;
      padding: 7rem 0 0 5rem;
    }
  }

  picture {
    min-width: 50%;
    max-width: 50%;
    fill: #003c68;
  }

  main {
    margin-left: auto;
    margin-right: auto;
    margin-top: 4rem;
    min-width: 31.5rem;
    flex: 1;

    ${mqNames(MEDIUM)} {
      width: 37.5rem;
      margin-top: 14.8rem;
    }
    ${mqNames(LARGE)} {
      margin-top: 1rem;
    }
  }

  .btgLogo {
    display: flex;
    justify-content: center;
    opacity: 0.5;
    margin: 2rem 0;
  }
`

const currentRoute = (
  pathname: string,
  location: WindowLocation | undefined
): boolean => !!location && location.pathname === pathname

export const UnauthenticatedLayout: React.FC<RouteComponentProps> = ({
  location,
  children,
}) => {
  const isUnlock = currentRoute(UNLOCK_ACCOUNT_PATH, location)
  const isResendUnlock = currentRoute(RESEND_UNLOCK_PATH, location)
  const isConfirm = currentRoute(RESEND_CONFIRMATION_PATH, location)

  return (
    <Container>
      <header>
        <TherasphereLogo fill="#003c68" height="100%" width="75%" />
      </header>
      <main>
        {children}
        <AccountLinks
          confirmation={!isConfirm}
          unlock={isUnlock}
          resendUnlock={!isResendUnlock}
        />
      </main>

      <div className="btgLogo">
        <BTGLogo height="100px" />
      </div>
    </Container>
  )
}

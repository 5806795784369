import React from 'react'
import { Box, Flex, Text } from '@chakra-ui/core'
import { themeUtils } from '../../../themeUtils'
import { CheckIcon, ExclamationMarkIcon } from '../../Icons'
import { BoxFill, ParagraphText } from '../../Shared'
import { TIMESTAMP_FORMAT } from '../../../constants'
import { formattedDate, toTitleCase } from '../../../utils'

interface StatusProps {
  active: boolean
  dateOfStatus?: string | number | Date | null
  status: string
  name?: string
  type?: string
  alert?: boolean
}

const { red, green, slateGrey } = themeUtils.colors

export const Status: React.FC<StatusProps> = ({
  active,
  children,
  dateOfStatus,
  status,
  name,
  type,
  alert,
}) => {
  const displayName = (): string | null => {
    const cancellingOrActive =
      ['void', 'cancellation requested', 'cancellation confirmed'].includes(
        status
      ) || active

    if (cancellingOrActive && name && type && type !== 'User') {
      return name
    } else if ((active && type === '') || type === null || type === 'User') {
      return 'BSC Customer Care'
    } else {
      return null
    }
  }

  return (
    <Flex marginBottom="2.5rem" alignItems={'center'}>
      <BoxFill
        width="4rem"
        height="4rem"
        circular
        backgroundColor={alert ? red : active ? green : undefined}
        boxShadow={active ? '0 8px 10px rgba(69, 218, 122, 0.2)' : undefined}
      >
        {!alert && <CheckIcon width="20" height="22" />}
        {alert && <ExclamationMarkIcon width="20" height="22" fill={'white'} />}
      </BoxFill>
      <Box marginLeft="2rem" lineHeight="2.2rem" fontSize="1.5rem">
        <ParagraphText variant="title">{toTitleCase(status)}</ParagraphText>
        {dateOfStatus && (
          <Text color={slateGrey}>
            {formattedDate(dateOfStatus, TIMESTAMP_FORMAT)}
          </Text>
        )}
        {children}

        {displayName() && `by ${displayName()}`}
      </Box>
    </Flex>
  )
}

import React, { useEffect } from 'react'
import { Redirect, RouteComponentProps } from '@reach/router'
import { useQuery } from '@apollo/react-hooks'
import { useMedia } from 'use-media'
import styled from '@emotion/styled'
import { Box, Flex } from '@chakra-ui/core'
import {
  BackButton,
  Container,
  CustomerName,
  Heading,
  ResponsiveButton,
  SubText,
} from '../../components/Shared'
import { useAuth } from '../../context/auth-context'
import {
  RequestCancellationButton,
  OrderShowShippingWidget,
  StatusWidget,
  CustomerCareContent,
  TreatmentsWidget,
  BillingInformationWidget,
  MissingDetailsWidget,
} from '../../components/Orders'
import { ORDERS_SHOW_QUERY } from '../../schema/queries'
import {
  OrderShow,
  OrderShowVariables,
} from '../../schema/queries/__generated__/OrderShow'
import { MEDIUM, mqNames, X_LARGE } from '../../themeUtils'
import { CANCELLABLE_STATUSES } from '../../constants'
import { Notification } from '../../utils'
import { ORDERS_INDEX_PATH } from '../../routes'
import { OrderStatusEnum } from '../../__generated__/globalTypes'
import { DownloadSquareIcon } from '../../components/Icons'
import { useTranslation } from 'react-i18next'

type Props = RouteComponentProps<{ orderId: string }>

const GridWrapper = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-areas:
    'content-1'
    'content-2'
    'content-3';

  ${mqNames(MEDIUM)} {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      'content-1 content-2'
      'content-3 content-3';
  }

  ${mqNames(X_LARGE)} {
    grid-template-columns: 1fr 1.3fr 1.5fr;
    grid-template-rows: 1fr;
    grid-template-areas: 'content-1 content-2 content-3';
  }
`

const Container1Grid = styled(Container)`
  display: grid;
  grid-area: content-1;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  height: '100%';
  position: 'relative';
`

const Container2Grid = styled(Container)`
  display: grid;
  grid-area: content-2;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
`

const Container3Grid = styled.div`
  display: grid;
  grid-row-gap: 1rem;
  grid-area: content-3;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  width: 'initial';
`

export const OrderShowScreen: React.FC<Props> = ({ orderId }) => {
  const authContext = useAuth()
  const { t } = useTranslation(['orderShowScreen'])
  const { data, loading, error, refetch } = useQuery<
    OrderShow,
    OrderShowVariables
  >(ORDERS_SHOW_QUERY, {
    variables: { id: orderId as string },
  })

  useEffect(() => {
    if (error) Notification.error(error.message, { timeout: 10000 })
  }, [error])

  const isXSmall = useMedia({ maxWidth: '375px' })

  if (loading) return <div>{t('loadingText', { ns: ['orderShowScreen'] })}</div>

  // if user does not have access to the order it will
  // return a null response, so redirect back to orders index
  if (!data || !data.order) {
    Notification.error(t('missingPermission', { ns: ['orderShowScreen'] }), {
      timeout: 10000,
    })
    return <Redirect noThrow to={ORDERS_INDEX_PATH} />
  }

  const { order } = data
  const isCancellable = CANCELLABLE_STATUSES.includes(order.status)
  const cancellationRequested = [
    OrderStatusEnum.CANCELLING,
    OrderStatusEnum.CANCELLED,
    OrderStatusEnum.VOID,
  ].includes(order.status)

  function printOrdersScreen(): void {
    window.print()
  }

  return (
    <>
      <Flex
        direction={{ base: 'column', md: 'row' }}
        paddingY="2rem"
        justifyContent="space-between"
      >
        <Flex alignItems="center">
          <BackButton />
          <Box marginLeft="2rem">
            <Heading as="h1" marginBottom="0">
              {orderId}
            </Heading>
            <SubText>
              <CustomerName />
            </SubText>
          </Box>
        </Flex>

        <Flex
          flexWrap="wrap"
          direction={isXSmall ? 'column' : 'row'}
          alignItems="center"
          marginBottom={{ base: '2rem', md: '0' }}
        >
          <ResponsiveButton
            leftIcon={DownloadSquareIcon}
            responsiveIcon={DownloadSquareIcon}
            ariaLabel={t('generate', { ns: ['orderShowScreen'] })}
            mr={4}
            onClick={printOrdersScreen}
          >
            {t('generate', { ns: ['orderShowScreen'] })}
          </ResponsiveButton>
          {isCancellable && authContext?.user?.canCreateOrders && (
            <RequestCancellationButton order={order} refetch={refetch} />
          )}
        </Flex>
      </Flex>
      <GridWrapper>
        <Container1Grid
          highlight={cancellationRequested}
          heading={t('statusHeading', { ns: ['orderShowScreen'] })}
        >
          <StatusWidget order={order} />
        </Container1Grid>
        <Container2Grid heading={t('detailsHeading', { ns: ['orderShowScreen'] })}>
          <OrderShowShippingWidget order={order} refetch={refetch}/>
          <TreatmentsWidget order={order} />
        </Container2Grid>
        <Container3Grid>
          <Container height="calc(100% - 5rem)" heading={t('billing', { ns: ['orderShowScreen'] })}>
            <BillingInformationWidget order={order} refetch={refetch} />
          </Container>
          <Container
            display="flex"
            flexDirection={{ base: 'column', md: 'row' }}
            alignContent="center"
            justifyContent="space-between"
            paddingTop="0.5rem"
            heading={t('contact', { ns: ['orderShowScreen'] })}
          >
            <CustomerCareContent layout="order" topPad />
          </Container>
        </Container3Grid>
      </GridWrapper>
      <MissingDetailsWidget order={order} refetch={refetch}/>
    </>
  )
}

import React from 'react'
import { OrdersIndex_orders_items } from '../../../schema/queries/__generated__/OrdersIndex'
import { navigate } from '@reach/router'
import { format, parse } from 'date-fns'
import styled from '@emotion/styled'
import { formatDate, formattedDate } from '../../../utils'
import { StatusTag } from '../../Shared'
import { ArrowIcon } from '../../Icons'
import { MEDIUM, mqNames, themeUtils } from '../../../themeUtils'
import { SHORT_MONTH_FORMAT } from '../../../constants'
import { OrderStatusEnum } from '../../../__generated__/globalTypes'
import '../../../SharedStyles.css'
import { useTranslation } from 'react-i18next'
import { getTimeFormat } from '../../../i18n'

interface Props {
  order: OrdersIndex_orders_items
  isDistributor: boolean
  firstItem?: boolean
}

const TableCell = styled.td`
  max-width: 300px;
  padding: 2.5rem 0;

  &:first-of-type {
    text-transform: uppercase;
  }

  @media print {
    font-size: 1rem;
  }
`

const ResponsiveTableCell = styled(TableCell)`
  @media screen {
    display: none;

    ${mqNames(MEDIUM)} {
      display: table-cell;
    }
  }
`

const TableRow = styled.tr`
  border-top: 1px solid ${themeUtils.colors.borderGrey};
  &:hover {
    background-color: ${themeUtils.colors.lightGrey};
  }
`

export const OrderRow: React.FC<Props> = ({ order, isDistributor,firstItem = true }) => {
  const { t } = useTranslation(['orderScreen'])
  const clickHandler = (): void => {
    navigate(`/orders/${order.orderNumber}`)
  }
  const doses: string[] = order.doses.reduce((array, dose) => {
    for (let index = 0; index < dose.quantity; index++) {
      array.push(`${dose.dosage.toFixed(1)} GBq`)
    }
    return array
  }, Array<string>())

  const accessoriesTotal = order.accessories.reduce((acc, val) => {
    acc += val.quantity
    return acc
  }, 0)

  const deliveryAccessoriesTotal = order.accessories.reduce((acc, val) => {
    if (val.delivery) {
      acc += val.quantity
    }
    return acc
  }, 0)

  const actualAccessoriesTotal = accessoriesTotal - deliveryAccessoriesTotal

  const hasAccessories = actualAccessoriesTotal > 0

  const doseText =
    doses.length > 0
      ? doses.join(', ') + (hasAccessories ? `, ${actualAccessoriesTotal}x A` : '')
      : `${actualAccessoriesTotal}x A`
  const patientRef = order.patientRef ? order.patientRef : t('orderTable.patientRefErr', { ns: ['orderScreen'] })
  const treatmentTime = parse(order.treatmentTime, 'HH:mm', new Date())
  const formattedTreatmentTime = format(treatmentTime, getTimeFormat())

  const isShipped =
    order.status === OrderStatusEnum.SHIPPED && order.actualShipDate

  return (
    <TableRow onClick={clickHandler} style={{ cursor: 'pointer' }}>
      { isDistributor && <TableCell>{firstItem && order.customerName}</TableCell> }
      <TableCell>{firstItem && patientRef}</TableCell>
      <TableCell>
        {firstItem &&
          !!order.treatmentDate &&
          `${formatDate(
            order.treatmentDate,
            SHORT_MONTH_FORMAT
          )} ${formattedTreatmentTime}`}
      </TableCell>
      <ResponsiveTableCell>{doseText}</ResponsiveTableCell>
      <ResponsiveTableCell>{order.orderNumber}</ResponsiveTableCell>
      <TableCell className="hide-for-screen">
        {isShipped
          ? formattedDate(order.actualShipDate)
          : `${t('shipped.expected', { ns: ['orderScreen'] })} ${formattedDate(order.shipDate)}`}
      </TableCell>
      <TableCell>
        <StatusTag status={order.status} customerPortalOrder={order.customerPortalOrder} reservedAt={order.reservedAt} />
      </TableCell>
      <ResponsiveTableCell className="hide-for-print">
        <ArrowIcon title={t('arrowIcon.hintText', { ns: ['orderScreen'] })} fill="#333333" />
      </ResponsiveTableCell>
    </TableRow>
  )
}
